<template>
  <v-container fluid>
    <v-card v-if="model">
      <v-card-title>
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div class="mainTitle">{{ model.name | capitalize }} (SID:{{ model.sid }})</div>
          <v-chip
            class="mr-2 roleChip"
            :color="model.role === 'manager' ? 'managerColor' : undefined"
            label
            >{{ model.role | capitalize }}</v-chip
          >
        </div>
        <div v-if="model.role !== 'client'">
          <v-btn-toggle
            mandatory
            color="primary"
            v-model="model.blocked"
            :disabled="!read"
            v-if="isAdmin"
          >
            <v-btn
              min-width="110"
              min-height="56"
              large
              :value="false"
              :class="!model.blocked ? 'primary--text v-item--active v-btn--active' : ''"
              @click="blocked('active')"
              >Active
            </v-btn>
            <v-btn
              large
              min-width="110"
              min-height="56"
              :value="true"
              :class="model.blocked ? 'primary--text v-item--active v-btn--active' : ''"
              @click="blocked('blocked')"
              >Blocked
            </v-btn>
          </v-btn-toggle>
          <custom-dialog ref="confirm"></custom-dialog>
        </div>
      </v-card-title>
      <v-divider style="border-color: rgba(0, 0, 0, 0.05)"></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="form" v-model="valid">
              <div class="d-flex mb-4">
                <div class="mainTitle" style="align-self: center">Update Profile</div>
                <v-spacer />
                <v-btn
                  class="mr-2"
                  v-if="role === 'admin' && (isAgent || isManager)"
                  @click="resetUsername"
                  color="primary"
                >
                  Reset Username
                </v-btn>
                <v-btn
                  class="mr-2"
                  v-if="role === 'admin' && (isAgent || isManager)"
                  @click="resetPassword"
                  color="primary"
                >
                  Reset Password
                </v-btn>
                <v-btn :disabled="!valid" color="success" @click="save">Save</v-btn>
                <custom-dialog ref="confirm" />
              </div>
              <!-- 這個是包著4個components的 -->
              <div class="displayTwoColumn">
                <div class="wrapInputInfo">
                  <v-row class="mb-0">
                    <div class="topInput">
                      <v-col style="height: 40px" class="pb-0" :cols="12">
                        <label-with-text-field
                          class="inputInfo pa-0"
                          item-field-name="Name"
                          v-model="model.name"
                          :rules="rules.nameRules"
                          :required="true"
                          :hide-details="valid"
                        />
                      </v-col>
                      <v-col class="pb-0" :cols="12">
                        <label-with-text-field
                          class="inputInfo"
                          item-field-name="Phone"
                          v-model="model.phone"
                          :readonly="isClient"
                          :hide-details="valid"
                        />
                      </v-col>
                      <template v-if="!isClient">
                        <v-col class="pb-0 pt-0" :cols="12">
                          <label-with-text-field
                            class="inputInfo"
                            item-field-name="Username"
                            v-model="model.user.username"
                            disabled
                            :hide-details="valid"
                          />
                        </v-col>
                        <v-col class="pb-0 pt-0" :cols="12">
                          <label-with-text-field
                            class="inputInfo"
                            :hide-details="valid"
                            item-field-name="Email"
                            v-model="model.email"
                          />
                        </v-col>
                      </template>
                    </div>
                  </v-row>
                  <!-- <v-row v-if="!isClient" style="margin-top: 0">
                  <v-col :cols="6">
                    <label-with-text-field
                      item-field-name="Username"
                      v-model="model.user.username"
                      disabled
                      hide-details
                    />
                  </v-col>
                  <v-col :cols="6">
                    <label-with-text-field
                      hide-details
                      item-field-name="Email"
                      v-model="model.email"
                    />
                  </v-col>
                </v-row> -->

                  <template v-if="isManager || isAgent">
                    <!--       <v-divider class="mb-2" /> -->
                    <work-period-card v-model="model.workTimePeriod" />
                  </template>
                </div>
                <div class="chatInfo">
                  <template v-if="isAgent && model.assignedChats && !!model.assignedChats.length">
                    <!--   <v-divider class="mb-2" /> -->
                    <div class="mainTitle my-3" style="text-decoration: underline">
                      Assigned Chats:
                    </div>
                    <v-row class="d-flex flex-column">
                      <v-col cols="12" v-for="(item, i) in model.assignedChats" :key="i">
                        <chatCard
                          :isAssignedChat="true"
                          :docs="item"
                          class="clickable"
                          @click.native="actionDetails(item._id)"
                        ></chatCard>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <!--        <v-divider class="mb-2" /> -->
                    <div v-if="isManager || isAgent">
                      <div class="mainTitle my-3" style="text-decoration: underline">
                        Assigned Chats:
                      </div>
                      <v-row>
                        <v-col cols="4">
                          <h3>No Assign Chats To Be Displayed</h3>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <template v-if="isSender && chatList && !!chatList.length">
                    <!--    <v-divider class="mb-2" /> -->
                    <div class="mainTitle my-3" style="text-decoration: underline">
                      Related Chats:
                    </div>
                    <v-row>
                      <v-col cols="12" v-for="(item, i) in chatList" :key="i">
                        <chatCard
                          :docs="item"
                          class="clickable"
                          @click.native="actionDetails(item._id)"
                        ></chatCard>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-else>
                    <!--   <v-divider class="mb-2" /> -->
                    <div v-if="isClient">
                      <div class="mainTitle my-3" style="text-decoration: underline">
                        Related Chats:
                      </div>
                      <v-row>
                        <v-col cols="4">
                          <h3>No Related Chats To Be Displayed</h3>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <template v-if="isClient && !isSender">
                    <!--  <v-divider class="mb-2" /> -->
                    <div v-if="!isAdmin">
                      <div class="mainTitle my-3" style="text-decoration: underline">
                        Client Tags
                      </div>
                      <div>{{ items }}</div>
                      <v-autocomplete
                        ref="input"
                        outlined
                        dense
                        v-model="model.tags"
                        :items="items"
                        :search-input.sync="autoSearch"
                        return-object
                        hide-selected
                        hide-no-data
                        chips
                        deletable-chips
                        multiple
                        :item-value="x => x"
                      >
                        <v-list-item v-for="(item, i) in items" :key="i">
                          <v-list-item-content>
                            <v-list-item-title v-text="item"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-autocomplete>
                    </div>
                    <div v-else>
                      <div class="mainTitle my-3" style="text-decoration: underline">
                        Client Tags
                      </div>
                      <v-list-item v-for="(item, i) in model.tags" :key="i">
                        <v-list-item-content>
                          <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </template>

                  <template v-else>
                    <div v-if="isClient">
                      <div class="mainTitle my-3" style="text-decoration: underline">
                        Client Chats:
                      </div>
                      <v-row>
                        <v-col cols="4">
                          <h3>No Client Tags To Be Displayed</h3>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { patchProfile, readProfile } from '@/api/auth/membership';
import { forgetPassword } from '@/api/auth/sso';
import labelWithTextField from '../../components/customFormLabel/labelWithTextField';
import { mapActions, mapGetters } from 'vuex';
import CustomDialog from '@/components/CustomDialog';
import chatCard from '@/components/Identity/chatCard';
import { getOid } from '@/services/utils';
import { chatList } from '@/api/chat';
import { getMyTeam } from '@/api/team';
import workPeriodCard from '@/components/Team/workPeriodCard';

export default {
  name: 'IdentityDetails',
  components: {
    chatCard,
    labelWithTextField,
    CustomDialog,
    workPeriodCard,
  },
  props: {
    id: {},
  },
  data() {
    return {
      valid: false,
      model: null,
      checkbox: false,
      loginRequired: 0,
      dialog: false,
      rules: {
        nameRules: [v => !!v || 'Name is required'],
      },
      autoSearch: '',
      items: [],
      link: {},
      chatList: null,
    };
  },
  watch: {
    'model.tags'() {
      if (this.model.tags.length) {
        this.autoSearch = '';
      }
    },
    async autoSearch(val) {
      await this.searchFunction(val);
    },
    id() {
      this.reload();
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async searchFunction(val) {
      try {
        const res = await getMyTeam(api, {
          search: val,
          select: 'clientTags',
        });
        this.items = res?.clientTags;
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async reload() {
      this.model = await readProfile(api, this.id, {
        populate: [
          { path: 'user' },
          { path: 'team' },
          {
            path: 'assignedChats',
            populate: { path: 'participants', match: { isSender: { $ne: true } } },
          },
        ],
      });
      if (this.model.workTimePeriod && this.model.team.workTimePeriod) {
        this.model.workTimePeriod.timezone = this.model.team.workTimePeriod.timezone;
      }
      if (this.model.isSender === true) {
        this.chatList = (
          await chatList(api, {
            filter: { participants: this.id },
            populate: [{ path: 'participants', match: { isSender: { $ne: true } } }],
          })
        ).docs;
      }
    },
    actionDetails(x) {
      this.$router.push({ name: 'Chat', params: { id: getOid(x) } }).catch(() => {});
    },
    async save() {
      try {
        await patchProfile(api, this.id, this.model);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async blocked(a) {
      let msg = '';
      const currentStatus = this.model.blocked;
      switch (a) {
        case 'blocked': {
          if (this.model.blocked === true) return;
          msg = `${this.model.name} will be blocked from login and working. Confirm to block?`;
          break;
        }
        case 'active': {
          if (this.model.blocked === false) return;
          msg = `${this.model.name} will be active for login and working. Confirm to activate?`;
          break;
        }
      }
      if (a === 'blocked' || a === 'active') {
        const status = await this.$refs.confirm.open('Status', msg, {
          color: 'danger',
          width: '30%',
        });
        if (status === true) {
          this.model.blocked = !currentStatus;
          try {
            await patchProfile(api, this.id, this.model);
            await this.$store.dispatch('alert/updateAlertMessage', {
              msg: 'Saved',
              type: 'success',
              color: 'success',
            });
          } catch (err) {
            await this.$store.dispatch('alert/updateAlertMessage', {
              msg: err,
            });
          }
        } else {
          this.model.blocked = currentStatus;
        }
      }
    },
    async resetUsername() {
      const confirm = await this.$refs.confirm.open(
        'Confirm',
        'You are leaving this page. All changes you may have made will be lost.\nPress Yes to continue.',
        {
          color: 'danger',
          width: '30%',
        },
      );
      if (confirm) {
        this.$router
          .push({ name: 'ChangeUsername', params: { id: this.model.user.id } })
          .catch(() => {});
      }
    },
    async resetPassword() {
      try {
        let temp = await readProfile(api, this.id, {
          populate: [{ path: 'user' }],
        });
        if (temp.user) {
          await forgetPassword(api, { email: this.model.email });
        }
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Reset email is sent to user',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),
    isClient() {
      return this.model.role === 'client';
    },
    isSender() {
      return this.model.role === 'client' && this.model.isSender;
    },
    isAgent() {
      return this.model.role === 'agent';
    },
    isManager() {
      return this.model.role === 'manager';
    },
    read() {
      const userRank = this.role === 'admin' ? 3 : this.role === 'manager' ? 2 : 0;
      const rank = this.model.role === 'admin' ? 3 : this.model.role === 'manager' ? 2 : 0;
      return userRank > rank;
    },
  },
};
</script>

<style>
.inputInfo {
  border-radius: 5px;
}
</style>
<style scoped lang="scss">
.fullWidth {
  width: 100%;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

#app .v-btn-toggle > .v-btn.v-btn--active {
  color: white !important;
}

#app .wrapInputInfo {
  width: 48%;
  margin: 25px 0px 25px 25px;
}

.displayTwoColumn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--v-twoColumnBackgroundColor-base);
  border: 4px solid var(--v-borderColor-base);
  border-radius: 5px;
}

.chatInfo {
  background-color: white;
  padding: 12px;
  width: 48%;
  margin: 25px;
  border-radius: 5px;
}

.topInput {
  width: 100%;
}
.topInput > * {
  margin-bottom: 10px;
}
/* .v-application .success {
  background-color: var(--v-drawerHover-base) !important;
}
.v-application .primary {
  background-color: var(--v-drawerHover-base) !important;
  border-color: var(--v-drawerHover-base) !important;
}
.v-application .primary--text {
  background-color: var(--v-drawerHover-base);
  caret-color: white;
} */
/* .v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select.v-select--chips.v-select--is-menu-active.v-select--is-multi.v-autocomplete.primary--text {
  background-color: transparent !important;
}
.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select.v-select--chips.v-select--is-multi.v-autocomplete.primary--text {
background-color: transparent !important;
} */
</style>

<style>
.displayTwoColumn .v-input__slot {
  width: 200px !important;
  min-height: 40px !important;
  padding-bottom: 0 !important;
}
</style>
