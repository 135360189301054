<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="d-flex">
          <h2 class="mainTitle">{{ title }}</h2>
          <v-spacer />
          <v-btn text @click="$emit('reload')">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon color="primary" v-if="enableCreate" @click="$emit('create')">
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex justify-space-between">
      <div class="d-flex justify-space-between">
        <v-text-field
          color="drawerHover"
          solo
          filled
          prepend-inner-icon="mdi-magnify"
          v-model="searchVal"
          class="textField mr-4"
        />
        <div>
          <slot name="filter"></slot>
        </div>
      </div>

      <div>
        <slot name="showTableBtn"></slot>
      </div>
    </div>

    <v-row v-if="$scopedSlots.card">
      <v-col cols="4" :key="x._id" v-for="x in data.docs">
        <slot name="card" :cardContent="x"> </slot>
      </v-col>
    </v-row>
    <v-row v-if="$scopedSlots.table">
      <v-col>
        <slot name="table"> </slot>
      </v-col>
    </v-row>
    <div class="px-15 mt-4">
      <div>
        <v-pagination
          v-model="pageVal"
          :length="data.pages || 1"
          :total-visible="10"
          color="drawerHover"
        />
      </div>
    </div>
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'ListView',
  props: {
    title: {},
    search: {},
    limit: { default: 10 },
    page: {},
    data: { default: () => ({}) },
    enableCreate: { default: true },
    populate: {},
  },
  emits: ['create', 'reload'],
  computed: {
    searchVal: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.textField {
  max-width: 100% !important;
}
</style>
