<template>
  <v-form ref="form">
    <v-card flat>
      <v-card-text class="workingPeriod d-flex flex-column">
        <div class="d-flex align-center mb-3">
          <span class="itemFieldName" style="white-space: nowrap; color: grey">Working Period</span>
        </div>
        <v-row>
          <v-col>
            <div class="align-center ml-8">
              <v-autocomplete
                label="Timezone"
                class="mt-3 mb-3"
                v-model="timezone"
                :items="timezones"
                hide-details
                outlined
              />
              <v-spacer />
            </div>
          </v-col>
        </v-row>
        <v-row class="f-flex flex-column startAndEnd">
          <v-col class="pl-0">
            <template>
              <v-menu
                v-model="startTimeMenu"
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startTime"
                    label="Start"
                    prepend-icon="mdi-clock-time-four-outline"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  />
                </template>
                <v-time-picker v-if="startTimeMenu" v-model="startTime" full-width />
              </v-menu>
            </template>
          </v-col>
          <v-col class="pl-0">
            <template>
              <v-menu
                v-model="endTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endTime"
                    label="End"
                    readonly
                    prepend-icon="mdi-clock-time-four-outline"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker v-if="endTimeMenu" v-model="endTime" full-width />
              </v-menu>
            </template>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex pl-0 ml-8">
          <!--          <v-spacer /> -->
          <v-btn color="warning" @click="clean"> Clean Time </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment';
import timezones from '@/services/timezones.json';

export default {
  name: 'workPeriodCard',
  data() {
    return {
      startTimeMenu: false,
      endTimeMenu: false,
    };
  },
  props: {
    value: {},
  },
  methods: {
    async clean() {
      this.$emit('input', { ...this.value, start: null, end: null, timezone: null });
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    timezones() {
      return timezones.map(x => ({
        /* 不知道是什麼東西來的 */
        text: x.text, // `(${x.offset >= 0 ? '+' + x.offset : x.offset}) ${x.value}`,
        value: x.offset,
      }));
    },
    timezone: {
      set(v) {
        if (v) {
          this.value.timezone = v * 60;
        }
      },
      get() {
        if (this.value.timezone != null) {
          return this.value.timezone / 60;
        }
      },
    },
    startTime: {
      get() {
        if (this.value?.start == null) return null;
        return moment(this.value.start).utcOffset(this.value.timezone).format('HH:mm');
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          start: moment(`2021-08-12 ${v}:00`).utcOffset(this.value.timezone, true).toDate(),
        });
      },
    },
    endTime: {
      get() {
        if (this.value?.end == null) return null;
        return moment(this.value.end).utcOffset(this.value.timezone).format('HH:mm');
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          end: moment(`2021-08-12 ${v}:00`).utcOffset(this.value.timezone, true).toDate(),
        });
      },
    },
  },
};
</script>

<style scoped>
.itemFieldName {
  font-weight: 600;
  color: #525f7f;
  font-size: 14px;
}

.startAndEnd {
  width: 100%;
  margin: 0px !important;
}
.workingPeriod {
  padding: 0px;
  padding-left: 30px;
}
</style>
